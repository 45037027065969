<template>
  <div class="mission">
     <div class="search-list">
      <div class="label">宣教名称:</div>
      <el-input placeholder="请输入宣教名称" v-model="workName" class='wd300' clearable></el-input>
      <div class="label ml20">宣教分类：</div>
      <el-select
          placeholder="请选择分类"
          v-model="workType"
          clearable
          style="width:200px;"
        >
          <el-option
            v-for="(item,index) in typeList"
            :key="index"
            :value="item.value"
            :label="item.name"
          ></el-option>
      </el-select>
      <div class="label ml20">发布日期：</div>
      <el-date-picker
        v-model="dateTime"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd " value-format="yyyy-MM-dd">
      </el-date-picker>
      <el-button type='primary' @click='getList' class='ml20'>查询</el-button>
      <el-button class=' ml20' @click='reset'>重置</el-button>

    </div>
    <div class="table">
      <el-table
        class='mt50'
        :data='dataList'
        style="width: 100%"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
      >
        <!-- <el-table-column type='index' width='50' header-align="center" align="center"> </el-table-column> -->
        <el-table-column prop='workTypeName' label='宣教分类' header-align="center" align="center" > </el-table-column>
        <el-table-column prop='workName' label='宣教名称' header-align="center" align="center" > </el-table-column>
        <el-table-column prop='followUpName' label='关联筛查名称' header-align="center" align="center" > </el-table-column>
        <el-table-column prop='createTime' label='发布日期' header-align="center" align="center" > </el-table-column>
        <!-- <el-table-column prop='viewNum' label='已查看' header-align="center" align="center" > </el-table-column> -->
        <el-table-column prop='number' label='发布人数' header-align="center" align="center" > </el-table-column>
        <!-- <el-table-column prop='publishTime' label='发布形式' header-align="center" align="center" > </el-table-column> -->
        <!-- <el-table-column prop='publishTime' label='已发布' header-align="center" align="center" > </el-table-column> -->
        <el-table-column  label='操作' header-align="center" align="center" fixed="right"> 
          <template slot-scope="scope">
            <el-button type="text" size="small" @click='goToInfo(scope.row)'>详情</el-button>
            <!-- <el-button type="text" size="small" @click='goToEdit(scope.row)'>修改</el-button> -->
            <!-- <el-button type="text" size="small" @click='del(scope.row)' style='color:#f56c6c'>删除</el-button> -->
          </template>

        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name:'missionary',
   data(){
      return {
        workName:'',
        dataList:[],
        dataListLoading:false,
        dataListSelections: [], //选中的列
        pageNo: 1,
        pageSize: 10,
        totalPage: 0,
        workType:'',
        typeList:[],
        dateTime:['',''],
      }
    },
    mounted() {
      this.getTypeList()
      this.getList()
    },
    methods: {
      reset(){
        this.workName = ''
        this.workType = ''
        this.dateTime = ['','']
      },
      async getTypeList(){
       let teamsId = ''
       if(localStorage.currentTeam){
            teamsId =JSON.parse(localStorage.currentTeam).id

      }
      const {data:res} = await this.$http({
        url:this.$http.adornUrl('/dict/getSysDicts'),
        method:'get',
        params:{
          type:'宣教分类',
          pageSize:999,
          teamsId,
        }
      })
      // console.log(res,'res')
      this.typeList=res.data
    },
      async getList(){
        const user = JSON.parse(localStorage.getItem('userInfo'))
        let teamsId = "";
        let enertyId = "";
        if (localStorage.currentTeam) {
          teamsId = JSON.parse(localStorage.currentTeam).id;
          enertyId = JSON.parse(localStorage.currentTeam).entityId;
        }
        const {data:res} = await this.$http({
          url:this.$http.adornUrl('/missionaryWork/getMissionaryWorkFeedback'),
          method:'get',
          params:{
            workName:this.workName,
            pageSize:this.pageSize,
            pageNo:this.pageNo,
            // hospitalId:user.deptPid,
            // deptId:user.deptId,
            type:1,
            workType:this.workType,
            hospitalId:teamsId,
            deptId:enertyId,
            startTime:this.dateTime[0],
            endTime:this.dateTime[1],
          }
        })
        if(res.status){
           res.data.forEach(v=>{
            // this.userList.forEach(user=>{
            //   if(v.createBy==user.organBusiness.id){
            //     v.creator = user.organBusiness.name
            //   }
            // })
            // this.diseasesList.forEach(v2=>{
            //   if(v.workEnertyType==v2.value){
            //     v.disease=v2.name
            //   }
            // })
            // this.typeList.forEach(type=>{
            //   if(v.noticeType==type.value){
            //     v.workTypeName=type.name
            //   }
            // })
          })
          this.dataList = res.data
            this.totalPage = res.totalCount

          }
          this.dataListLoading = false
      },
      goToInfo(row){
        this.$router.push({path:'/feedback/missionary/info',query:{id:row.id,num:row.number}})
      },
      // 每页数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.pageNo = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.pageNo = val;
        this.getDataList();
      },
      // 多选
      selectionChangeHandle(val) {
        this.dataListSelections = val;
      },
    },
}
</script>
<style lang="scss" scoped>
.search-list{
    display: flex;
    align-items: center;
    .label{
      width: 5%;
    }
  }
  .mt50{
    margin-top: 50px;
  }
</style>